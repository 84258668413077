import { UserTasksActionTypes, UserTasksAction } from '../actions/userTasksActionTypes';

interface UserTasksState {
  data: string[];
  loading: boolean;
  error: string | null;
  updateData: string[];
  updateError: string | null;
  updating: boolean;
  deleteData: string[];
  deleteError: string | null;
  deleting: boolean;
  commentData: string[]
  commentError: string | null;
  addingComment: boolean;
}

const initialState: UserTasksState = {
  data: [],
  loading: false,
  error: null,
  updateData: [],
  updateError: null,
  updating: false,
  deleteData: [],
  deleteError: null,
  deleting: false,
  commentData: [],
  commentError: null,
  addingComment: false
};

const userTasksReducer = (state = initialState, action: UserTasksAction): UserTasksState => {
  switch (action.type) {
    case UserTasksActionTypes.USER_TASKS_REQUEST:
      return { ...state, loading: true };
    case UserTasksActionTypes.USER_TASKS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case UserTasksActionTypes.USER_TASKS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case UserTasksActionTypes.USER_TASKS_UPDATE_REQUEST:
      return { ...state, updating: true };
    case UserTasksActionTypes.USER_TASKS_UPDATE_REQUEST_SUCCESS:
      return { ...state, updateData: action.payload, updating: false, updateError: null };
    case UserTasksActionTypes.USER_TASKS_UPDATE_REQUEST_ERROR:
      return { ...state, updating: false, updateError: action.payload };

    case UserTasksActionTypes.USER_TASKS_DELETE_REQUEST:
      return { ...state, deleting: true };
    case UserTasksActionTypes.USER_TASKS_DELETE_REQUEST_SUCCESS:
      return { ...state, deleteData: action.payload, deleting: false, deleteError: null };
    case UserTasksActionTypes.USER_TASKS_DELETE_REQUEST_ERROR:
      return { ...state, deleting: false, deleteError: action.payload };

    case UserTasksActionTypes.USER_TASKS_ADD_COMMENT_REQUEST:
      return { ...state, addingComment: true };
    case UserTasksActionTypes.USER_TASKS_ADD_COMMENT_REQUEST_SUCCESS:
      return { ...state, commentData: action.payload, addingComment: false, commentError: null };
    case UserTasksActionTypes.USER_TASKS_ADD_COMMENT_REQUEST_ERROR:
      return { ...state, addingComment: false, commentError: action.payload };

    default:
      return state;
  }
};

export default userTasksReducer;
