export enum ManageResourcesActionTypes {
  MANAGE_RESOURCES = 'manageResources/MANAGE_RESOURCES',
  MANAGE_RESOURCES_SUCCESS = 'manageResources/MANAGE_RESOURCES_SUCCESS',
  MANAGE_RESOURCES_ERROR = 'manageResources/MANAGE_RESOURCES_ERROR',
  MANAGE_RESOURCES_UPDATE_REQUEST = 'manageResources/MANAGE_RESOURCES_UPDATE_REQUEST',
  MANAGE_RESOURCES_UPDATE_REQUEST_SUCCESS = 'manageResources/MANAGE_RESOURCES_UPDATE_REQUEST_SUCCESS',
  MANAGE_RESOURCES_UPDATE_REQUEST_ERROR = 'manageResources/MANAGE_RESOURCES_UPDATE_REQUEST_ERROR',
  MANAGE_RESOURCES_DELETE_REQUEST = 'manageResources/MANAGE_RESOURCES_DELETE_REQUEST',
  MANAGE_RESOURCES_DELETE_REQUEST_SUCCESS = 'manageResources/MANAGE_RESOURCES_DELETE_REQUEST_SUCCESS',
  MANAGE_RESOURCES_DELETE_REQUEST_ERROR = 'manageResources/MANAGE_RESOURCES_DELETE_REQUEST_ERROR'
}

export interface ManageResourcesAction {
  type: ManageResourcesActionTypes;
  payload?: any;
}
