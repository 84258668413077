import { takeLatest, put, call } from 'redux-saga/effects';
import { manageGradingAssistantsActionTypes } from '../actions/manageGradingAssistantsActionTypes';
import {
  manageGradingAssistantsRequestSuccess,
  manageGradingAssistantsRequestError
} from '../actions/manageGradingAssistantsActions';
import { apiService } from '../../../services/api';

function* manageGradingAssistantsRequestSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageGradingAssistants, req.payload);

    // Dispatch the success action with the API response
    yield put(manageGradingAssistantsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(manageGradingAssistantsRequestError(error.message));
  }
}

export function* watchmanageGradingAssistantsRequest() {
  yield takeLatest(manageGradingAssistantsActionTypes.MANAGE_GRADING_ASSISTANTS, manageGradingAssistantsRequestSaga);
}
