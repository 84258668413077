import {
  getGradingAssistantsActionTypes,
  getGradingAssistantsAction
} from '../actions/getGradingAssistantsActionTypes';

interface getGradingAssistantsState {
  data: string[];
  loading: boolean;
  load_error: string | null;
}

const initialState: getGradingAssistantsState = {
  data: [],
  loading: false,
  load_error: null
};

const getGradingAssistantsReducer = (
  state = initialState,
  action: getGradingAssistantsAction
): getGradingAssistantsState => {
  switch (action.type) {
    case getGradingAssistantsActionTypes.GET_GRADING_ASSISTANTS:
      return { ...state, loading: true };
    case getGradingAssistantsActionTypes.GET_GRADING_ASSISTANTS_SUCCESS:
      return { ...state, data: action.payload, loading: false, load_error: null };
    case getGradingAssistantsActionTypes.GET_GRADING_ASSISTANTS_ERROR:
      return { ...state, loading: false, load_error: action.payload };
    default:
      return state;
  }
};

export default getGradingAssistantsReducer;
