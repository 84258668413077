import { takeLatest, put, call } from 'redux-saga/effects';
import { apiService } from '../../../services/api';
import { FreePostingsActionTypes } from '../actions/freePostingsActionTypes';
import {
  getFreePostingsError,
  getFreePostingsSuccess,
  addFreePostingRequestSuccess,
  addFreePostingRequestError,
  updateFreePostingRequestSuccess,
  updateFreePostingRequestError
} from '../actions/freePostingsActions';

function* addFreePostingSaga(req: any) {
  try {
    const response = yield call(apiService.addFreePosting, req.payload);
    yield put(addFreePostingRequestSuccess(response));
  } catch (error: any) {
    yield put(addFreePostingRequestError(error.message));
  }
}

function* getFreePostingsSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getFreePostings, req.payload);
    // Dispatch the success action with the API response
    yield put(getFreePostingsSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getFreePostingsError(error.message));
  }
}

function* updateFreePostingSaga(req) {
  try {
    const response = yield call(apiService.updateFreePosting, req.payload);
    yield put(updateFreePostingRequestSuccess(response));
  } catch (error: any) {
    yield put(updateFreePostingRequestError(error.message));
  }
}

export function* watchFreePostingsRequest() {
  yield takeLatest(FreePostingsActionTypes.GET_FREE_POSTINGS_REQUEST, getFreePostingsSaga);
  yield takeLatest(FreePostingsActionTypes.ADD_FREE_POSTING_REQUEST, addFreePostingSaga);
  yield takeLatest(FreePostingsActionTypes.UPDATE_FREE_POSTING_REQUEST, updateFreePostingSaga);
}
