// features/login/reducers/loginReducer.ts
import { RegisterActionTypes, RegisterAction } from "../actions/RegisterUserActionTypes";

interface RegisterUserState {
  user: string | null;
  account: string | null;
  loading: boolean;
  error: string | null;
  registrationKey: string | null;
  registrationKeyLoading: boolean;
  registrationKeyError: string | null;
}

const initialState: RegisterUserState = {
  user: null,
  account: null,
  loading: false,
  error: null,
  registrationKey: null,
  registrationKeyLoading: false,
  registrationKeyError: null,
};

const registerUserReducer = (state = initialState, action: RegisterAction): RegisterUserState => {
  switch (action.type) {
    case RegisterActionTypes.REGISTER_ACCOUNT:
      return { ...state, loading: true };
    case RegisterActionTypes.REGISTER_ACCOUNT_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case RegisterActionTypes.REGISTER_ACCOUNT_ERROR:
      return { ...state, loading: false, error: action.payload };
    case RegisterActionTypes.REGISTER_USER:
      return { ...state, loading: true };
    case RegisterActionTypes.REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case RegisterActionTypes.REGISTER_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    case RegisterActionTypes.REQUEST_REGISTRATION_KEY:
      return { ...state, registrationKeyLoading: true };
    case RegisterActionTypes.REQUEST_REGISTRATION_KEY_SUCCESS:
      return { ...state, registrationKey: action.payload, registrationKeyLoading: false, registrationKeyError: null };
    case RegisterActionTypes.REQUEST_REGISTRATION_KEY_ERROR:
      return { ...state, registrationKeyLoading: false, registrationKeyError: action.payload };
    default:
      return state;
  }
};

export default registerUserReducer;
