import { takeLatest, put, call } from 'redux-saga/effects';
import { ManageResourcesActionTypes } from '../actions/manageResourcesActionTypes';
import { apiService } from '../../../services/api';
import {
  manageResourcesRequestSuccess,
  manageResourcesUpdateRequestSuccess,
  manageResourcesDeleteRequestSuccess,
  manageResourcesRequestError,
  manageResourcesUpdateRequestError,
  manageResourcesDeleteRequestError
} from '../actions/manageResourcesActions';

function* manageResourcesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageResources, req.payload);
    yield put(manageResourcesRequestSuccess(response));
    // Dispatch the success action with the API response
  } catch (error: any) {
    // Dispatch the error action
    yield put(manageResourcesRequestError(error.message));
  }
}

function* manageResourcesUpdateRequestSaga(req) {
  try {
    const response = yield call(apiService.manageResources, req.payload);
    yield put(manageResourcesUpdateRequestSuccess(response));
  } catch (error: any) {
    yield put(manageResourcesUpdateRequestError(error.message));
  }
}

function* manageResourcesDeleteRequestSaga(req) {
  try {
    const response = yield call(apiService.manageResources, req.payload);
    yield put(manageResourcesDeleteRequestSuccess(response));
  } catch (error: any) {
    yield put(manageResourcesDeleteRequestError(error.message));
  }
}

export function* watchmanageResourcesRequest() {
  yield takeLatest(ManageResourcesActionTypes.MANAGE_RESOURCES, manageResourcesRequestSaga);
  yield takeLatest(ManageResourcesActionTypes.MANAGE_RESOURCES_UPDATE_REQUEST, manageResourcesUpdateRequestSaga);
  yield takeLatest(ManageResourcesActionTypes.MANAGE_RESOURCES_DELETE_REQUEST, manageResourcesDeleteRequestSaga);
}
