// features/createClass/actions/createClassActions.ts
import {
  manageGradingAssistantsActionTypes,
  manageGradingAssistantsAction
} from './manageGradingAssistantsActionTypes';

export const manageGradingAssistantsRequest = (data: any): manageGradingAssistantsAction => ({
  type: manageGradingAssistantsActionTypes.MANAGE_GRADING_ASSISTANTS,
  payload: data
});

export const manageGradingAssistantsRequestSuccess = (data: any): manageGradingAssistantsAction => ({
  type: manageGradingAssistantsActionTypes.MANAGE_GRADING_ASSISTANTS_SUCCESS,
  payload: data
});

export const manageGradingAssistantsRequestError = (error: string): manageGradingAssistantsAction => ({
  type: manageGradingAssistantsActionTypes.MANAGE_GRADING_ASSISTANTS_ERROR,
  payload: error
});
