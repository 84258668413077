// features/createClass/actions/createClassActions.ts
import { ManageResourcesAction, ManageResourcesActionTypes } from './manageResourcesActionTypes';

export const manageResourcesRequest = (data: any): ManageResourcesAction => ({
  type: ManageResourcesActionTypes.MANAGE_RESOURCES,
  payload: data
});

export const manageResourcesRequestSuccess = (data: any): ManageResourcesAction => ({
  type: ManageResourcesActionTypes.MANAGE_RESOURCES_SUCCESS,
  payload: data
});

export const manageResourcesRequestError = (error: string): ManageResourcesAction => ({
  type: ManageResourcesActionTypes.MANAGE_RESOURCES_ERROR,
  payload: error
});

export const manageResourcesUpdateRequest = (data: any): ManageResourcesAction => ({
  type: ManageResourcesActionTypes.MANAGE_RESOURCES_UPDATE_REQUEST,
  payload: data
});

export const manageResourcesUpdateRequestSuccess = (data: any): ManageResourcesAction => ({
  type: ManageResourcesActionTypes.MANAGE_RESOURCES_UPDATE_REQUEST_SUCCESS,
  payload: data
});

export const manageResourcesUpdateRequestError = (error: string): ManageResourcesAction => ({
  type: ManageResourcesActionTypes.MANAGE_RESOURCES_UPDATE_REQUEST_ERROR,
  payload: error
});

export const manageResourcesDeleteRequest = (data: any): ManageResourcesAction => ({
  type: ManageResourcesActionTypes.MANAGE_RESOURCES_DELETE_REQUEST,
  payload: data
});

export const manageResourcesDeleteRequestSuccess = (data: any): ManageResourcesAction => ({
  type: ManageResourcesActionTypes.MANAGE_RESOURCES_DELETE_REQUEST_SUCCESS,
  payload: data
});

export const manageResourcesDeleteRequestError = (error: string): ManageResourcesAction => ({
  type: ManageResourcesActionTypes.MANAGE_RESOURCES_DELETE_REQUEST_ERROR,
  payload: error
});
