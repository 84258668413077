export enum GetResourcesActionTypes {
  GET_RESOURCES = 'getResources/GET_RESOURCES',
  GET_RESOURCES_SUCCESS = 'getResources/GET_RESOURCES_SUCCESS',
  GET_RESOURCES_ERROR = 'getResources/GET_RESOURCES_ERROR',
  GET_RESOURCES_BY_ID = 'getResources/GET_RESOURCES_BY_ID',
  GET_RESOURCES_BY_ID_SUCCESS = 'getResources/GET_RESOURCES_BY_ID_SUCCESS',
  GET_RESOURCES_BY_ID_ERROR = 'getResources/GET_RESOURCES_BY_ID_ERROR',
  GET_RESOURCES_BY_Filters = 'getResources/GET_RESOURCES_BY_Filters',
  STORE_RESOURCES_ON_CART = 'getResources/STORE_RESOURCES_ON_CART',
  RESET_RESOURCES_ON_CART = 'getResources/RESET_RESOURCES_ON_CART',
  FILTER_RESOURCES_ON_CART = 'getResources/FILTER_RESOURCES_ON_CART',
  REMOVE_RESOURCES_ON_CART = 'getResources/REMOVE_RESOURCES_ON_CART',
  REMOVE_RESOURCES_ON_CART_SUCCESS = 'getResources/REMOVE_RESOURCES_ON_CART_SUCCESS',
  REMOVE_RESOURCES_ON_CART_ERROR = 'getResources/REMOVE_RESOURCES_ON_CART_ERROR'
}

export interface GetResourcesAction {
  type: GetResourcesActionTypes;
  payload?: any;
}
