import { takeLatest, put } from 'redux-saga/effects';
import { NewPasswordActionTypes } from '../actions/NewPasswordActionTypes';
import { newPasswordUserSuccess, newPasswordUserError } from '../actions/newPasswordActions';
import { loginUserSuccess, loginUserError } from '../../login/actions/loginActions';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { setTokens } from '../../otherReducers/Gen3LearningActionCreators';

const AWS = require('aws-sdk');
AWS.config.update({ region: 'us-east-1' });

function* newPasswordUserSaga(action) {
  try {
    const req = action.payload;
    const poolData = {
      UserPoolId: req.UserPoolId,
      IdentityPoolId: req.IdentityPoolId,
      ClientId: req.ClientId
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: req.Username,
      Pool: userPool
    };
    const authenticationData = {
      Username: req.Username,
      Password: req.CurrentPassword,
      name: req.Username
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const cognitoUser = new CognitoUser(userData);
    var sessionUserAttributes;
    const authPromise = new Promise(async (resolve, reject) => {
      await cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result: any) {
          const idToken = result.getIdToken().getJwtToken();
          const accessToken = result.getAccessToken().getJwtToken();
          const d = new Date();
          const newPasswordRequired = false;
          localStorage.setItem('logintime', d.getTime().toString());
          resolve({ idToken, accessToken, newPasswordRequired });
        },
        onFailure: function (err) {
          console.log('Authentication failed:', err);
          reject(err);
          localStorage.setItem('isFirstLogin', 'false');
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          delete userAttributes.email_verified;
          delete userAttributes.phone_number_verified;
          sessionUserAttributes = userAttributes;
          cognitoUser.completeNewPasswordChallenge(
            req.ProposedPassword,
            {},
            {
              onSuccess: function (result) {
                const newPasswordRequired = false;
                const loggedIn = false;
                resolve({ newPasswordRequired, loggedIn, sessionUserAttributes });
              },
              onFailure: function (err) {
                console.log('Password reset failed:', err);
                localStorage.setItem('isFirstLogin', 'false');
                reject(err);
              }
            }
          );
        }
      });
    });
    try {
      const authResult = yield authPromise;
      if (authResult.idToken && authResult.accessToken) {
        yield put(
          setTokens({
            idToken: authResult.idToken,
            accessToken: authResult.accessToken
          })
        );
      }
      yield put(newPasswordUserSuccess(authResult));
      yield put(loginUserSuccess(authResult));
    } catch (error: any) {
      yield put(newPasswordUserError(error));
      yield put(loginUserError(error));
    }
  } catch (error: any) {
    yield put(newPasswordUserError(error));
    yield put(loginUserError(error));
  }
}

export function* watchNewPasswordUser() {
  yield takeLatest(NewPasswordActionTypes.NEW_PASSWORD_USER, newPasswordUserSaga);
}
