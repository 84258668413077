export enum getGradingAssistantsActionTypes {
  GET_GRADING_ASSISTANTS = 'getGradingAssistants/GET_GRADING_ASSISTANTS',
  GET_GRADING_ASSISTANTS_SUCCESS = 'getGradingAssistants/GET_GRADING_ASSISTANTS_SUCCESS',
  GET_GRADING_ASSISTANTS_ERROR = 'getGradingAssistants/GET_GRADING_ASSISTANTS_ERROR'
}

export interface getGradingAssistantsAction {
  type: getGradingAssistantsActionTypes;
  payload?: any;
}
