
import {
  FreePostingsAction,
  FreePostingsActionTypes
} from '../actions/freePostingsActionTypes';

interface FreePostingsState {
  addFreePostingData: any;
  addFreePostingLoading: boolean;
  addFreePostingError: string | null;
  getFreePostingsData: any[];
  getFreePostingsLoading: boolean;
  getFreePostingsError: string | null;
  updateFreePostingData: any;
  updateFreePostingLoading: boolean;
  updateFreePostingError: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: FreePostingsState = {
  addFreePostingData: null,
  addFreePostingLoading: false,
  addFreePostingError: null,
  getFreePostingsData: [],
  getFreePostingsLoading: false,
  getFreePostingsError: null,
  updateFreePostingData: null,
  updateFreePostingLoading: false,
  updateFreePostingError: null,
  loading: false,
  error: null
};

const freePostingsReducer = (
  state = initialState,
  action: FreePostingsAction
): FreePostingsState => {
  switch (action.type) {
    case FreePostingsActionTypes.ADD_FREE_POSTING_REQUEST:
      return { ...state, addFreePostingLoading: true, addFreePostingData: action.payload };
    case FreePostingsActionTypes.ADD_FREE_POSTING_REQUEST_SUCCESS:
      return { ...state, addFreePostingLoading: false, addFreePostingData: action.payload };
    case FreePostingsActionTypes.ADD_FREE_POSTING_REQUEST_ERROR:
      return { ...state, addFreePostingLoading: false, addFreePostingError: action.payload };
    case FreePostingsActionTypes.GET_FREE_POSTINGS_REQUEST:
      return { ...state, getFreePostingsLoading: true, getFreePostingsData: action.payload };
    case FreePostingsActionTypes.GET_FREE_POSTINGS_REQUEST_SUCCESS:
      return { ...state, getFreePostingsLoading: false, getFreePostingsData: action.payload };
    case FreePostingsActionTypes.GET_FREE_POSTINGS_REQUEST_ERROR:
      return { ...state, getFreePostingsLoading: false, getFreePostingsError: action.payload };
    case FreePostingsActionTypes.UPDATE_FREE_POSTING_REQUEST:
      return { ...state, updateFreePostingLoading: true, updateFreePostingData: action.payload };
    case FreePostingsActionTypes.UPDATE_FREE_POSTING_REQUEST_SUCCESS:
      return { ...state, updateFreePostingLoading: false, updateFreePostingData: action.payload };
    case FreePostingsActionTypes.UPDATE_FREE_POSTING_REQUEST_ERROR:
      return { ...state, updateFreePostingLoading: false, updateFreePostingError: action.payload };
    default:
      return state;
  }
};

export default freePostingsReducer;
