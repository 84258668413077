export enum RequestClassActionTypes {
  GET_REQUEST_CLASS_REQUEST = 'requestClass/GET_REQUEST_CLASS_REQUEST',
  GET_REQUEST_CLASS_REQUEST_SUCCESS = 'requestClass/GET_REQUEST_CLASS_REQUEST_SUCCESS',
  GET_REQUEST_CLASS_REQUEST_ERROR = 'requestClass/GET_REQUEST_CLASS_REQUEST_ERROR',
  REQUEST_CLASS_REQUEST = 'requestClass/REQUEST_CLASS_REQUEST',
  REQUEST_CLASS_REQUEST_SUCCESS = 'requestClass/REQUEST_CLASS_REQUEST_SUCCESS',
  REQUEST_CLASS_REQUEST_ERROR = 'requestClass/REQUEST_CLASS_REQUEST_ERROR',
  UPDATE_REQUEST_CLASS_REQUEST = 'requestClass/UPDATE_REQUEST_CLASS_REQUEST',
  UPDATE_REQUEST_CLASS_REQUEST_SUCCESS = 'requestClass/UPDATE_REQUEST_CLASS_REQUEST_SUCCESS',
  UPDATE_REQUEST_CLASS_REQUEST_ERROR = 'requestClass/UPDATE_REQUEST_CLASS_REQUEST_ERROR'
}


export interface RequestClassAction {
  type: RequestClassActionTypes;
  payload?: any;
}
