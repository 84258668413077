// features/login/sagas/loginSaga.ts
import { takeLatest, put, call } from 'redux-saga/effects';
import { RegisterActionTypes } from '../actions/RegisterUserActionTypes';
import {
  registerUserSuccess,
  registerUserError,
  registerUserAccountError,
  requestRegistrationKeySuccess,
  requestRegistrationKeyError
} from '../actions/registerUserActions';
import { apiService } from '../../../services/api'; // Update the path as needed
import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';

const AWS = require('aws-sdk');
AWS.config.update({ region: 'us-east-1' });

function* registerUserSaga(action) {
  try {
    const req = action.payload;

    // Simulate an asynchronous API call
    const response = yield call(apiService.registerUser, req);

    const client_id = response.client_id;
    const user_name = response.username;
    const identity_pool_id = response.identityPoolId;
    const groups = response.groups;
    const cognito_user_pool_id = response.cognito_user_pool_id;
    const user_api_url = response.userAPI;
    localStorage.setItem('user_api', user_api_url);
    localStorage.setItem('webSocketUrl', response.websocketURL);
    console.log('response', response);
    const registration_code = req.registration_code;
    let attributeList: CognitoUserAttribute[] = [];
    if (response.status === false) {
      alert(response.message);
    } else {
      const poolData: any = {
        UserPoolId: cognito_user_pool_id,
        IdentityPoolId: identity_pool_id,
        ClientId: client_id
      };
      const userPool = new CognitoUserPool(poolData);
      localStorage.setItem('userPool', cognito_user_pool_id);
      localStorage.setItem('identity_pool_id', identity_pool_id);
      localStorage.setItem('client_id', client_id);
      localStorage.setItem('user_name_with_email', user_name);
      localStorage.setItem('user_name', user_name.split('@')[0]);
      localStorage.setItem('user_groups', groups);
      localStorage.setItem('user_api', user_api_url);
      localStorage.setItem('registration_code', registration_code);
      const user_email_data = {
        Name: 'email',
        Value: req.email
      };
      const user_name_data = {
        Name: 'name',
        Value: req.first_name + ' ' + req.last_name
      };
      const user_phone_data = {
        Name: 'phone_number',
        Value: '+' + req.phone_number
      };
      const user_last_name_data = {
        Name: 'family_name',
        Value: req.last_name
      };
      const user_given_name_data = {
        Name: 'given_name',
        Value: req.first_name
      };
      const user_preferred_username_data = {
        Name: 'preferred_username',
        Value: req.email.split('@')[0]
      };
      attributeList.push(new CognitoUserAttribute(user_email_data));
      attributeList.push(new CognitoUserAttribute(user_name_data));
      attributeList.push(new CognitoUserAttribute(user_phone_data));
      attributeList.push(new CognitoUserAttribute(user_last_name_data));
      attributeList.push(new CognitoUserAttribute(user_given_name_data));
      attributeList.push(new CognitoUserAttribute(user_preferred_username_data));
      let validation: CognitoUserAttribute[] = [];

      const authPromise = new Promise((resolve, reject) => {
        userPool.signUp(user_name, req.password, attributeList, validation, (err, result: any) => {
          if (err) {
            console.log('Error signing up user:', err);
            console.log('result is', result);
            reject(err.message);
          } else {
            const cognitoUser = result.user;
            resolve(cognitoUser);
          }
        });
      });
      try {
        const authResult = yield authPromise;
        yield put(registerUserSuccess(authResult));
      } catch (error: any) {
        yield put(registerUserError(error));
      }
    }
  } catch (error: any) {
    yield put(registerUserAccountError(error));
  }
}

function* requestRegistrationKeySaga(action) {
  try {
    const req = action.payload;
    const response = yield call(apiService.requestRegistrationKey, req);
    yield put(requestRegistrationKeySuccess(response));
  } catch (error: any) {
    yield put(requestRegistrationKeyError(error));
  }
}

export function* watchRegisterUser() {
  yield takeLatest(RegisterActionTypes.REGISTER_USER, registerUserSaga);
  yield takeLatest(RegisterActionTypes.REQUEST_REGISTRATION_KEY, requestRegistrationKeySaga);
}
