import { ManageResourcesActionTypes, ManageResourcesAction } from '../actions/manageResourcesActionTypes';

interface ManageResourcesState {
  data: string[];
  loading: boolean;
  error: string | null;
  updatedData: string[];
  updating: boolean;
  updatedError: string | null;
  deletedData: string[];
  deleting: boolean;
  deletedError: string | null;
}

const initialState: ManageResourcesState = {
  data: [],
  loading: false,
  error: null,
  updatedData: [],
  updating: false,
  updatedError: null,
  deletedData: [],
  deleting: false,
  deletedError: null
};

const manageResourcesReducer = (state = initialState, action: ManageResourcesAction): ManageResourcesState => {
  switch (action.type) {
    case ManageResourcesActionTypes.MANAGE_RESOURCES:
      return { ...state, loading: true };
    case ManageResourcesActionTypes.MANAGE_RESOURCES_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case ManageResourcesActionTypes.MANAGE_RESOURCES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ManageResourcesActionTypes.MANAGE_RESOURCES_UPDATE_REQUEST:
      return { ...state, updating: true };

    case ManageResourcesActionTypes.MANAGE_RESOURCES_UPDATE_REQUEST_SUCCESS:
      return { ...state, updatedData: action.payload, updating: false, updatedError: null };

    case ManageResourcesActionTypes.MANAGE_RESOURCES_UPDATE_REQUEST_ERROR:
      return { ...state, updating: false, updatedError: action.payload };

    case ManageResourcesActionTypes.MANAGE_RESOURCES_DELETE_REQUEST:
      return { ...state, deleting: true };

    case ManageResourcesActionTypes.MANAGE_RESOURCES_DELETE_REQUEST_SUCCESS:
      return { ...state, deletedData: action.payload, deleting: false, deletedError: null };

    case ManageResourcesActionTypes.MANAGE_RESOURCES_DELETE_REQUEST_ERROR:
      return { ...state, deleting: false, deletedError: action.payload };

    default:
      return state;
  }
};

export default manageResourcesReducer;
