import { Gen3RewardsActionTypes, Gen3RewardsAction } from '../actions/gen3RewardsActionTypes';

interface Gen3RewardsState {
  data: string[];
  claimData: string[];
  updateData: string[];
  loading: boolean;
  error: string | null;
  updating: boolean;
  updateError: string | null;
}

const initialState: Gen3RewardsState = {
  data: [],
  claimData: [],
  updateData: [],
  loading: false,
  error: null,
  updating: false,
  updateError: null
};

const gen3RewardsReducer = (state = initialState, action: Gen3RewardsAction): Gen3RewardsState => {
  switch (action.type) {
    case Gen3RewardsActionTypes.GEN3_REWARDS_REQUEST:
      return { ...state, loading: true };
    case Gen3RewardsActionTypes.GEN3_REWARDS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case Gen3RewardsActionTypes.GEN3_REWARDS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case Gen3RewardsActionTypes.GEN3_REWARDS_CLAIM_REQUEST:
      return { ...state, loading: true };
    case Gen3RewardsActionTypes.GEN3_REWARDS_CLAIM_REQUEST_SUCCESS:
      return { ...state, claimData: action.payload, loading: false, error: null };
    case Gen3RewardsActionTypes.GEN3_REWARDS_CLAIM_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case Gen3RewardsActionTypes.GEN3_UPDATE_REWARDS:
      return { ...state, updating: true };
    case Gen3RewardsActionTypes.GEN3_UPDATE_REWARDS_SUCCESS:
      return { ...state, updateData: action.payload, updating: false, error: null };
    case Gen3RewardsActionTypes.GEN3_UPDATE_REWARDS_ERROR:
      return { ...state, updating: false, updateError: action.payload };
    default:
      return state;
  }
};

export default gen3RewardsReducer;
