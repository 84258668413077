import {
  RequestClassAction,
  RequestClassActionTypes,
} from "./requestClassActionTypes";

export const getRequestClass = (data: any): RequestClassAction => ({
  type: RequestClassActionTypes.GET_REQUEST_CLASS_REQUEST,
  payload: data,
});

export const getRequestClassSuccess = (data: any): RequestClassAction => ({
  type: RequestClassActionTypes.GET_REQUEST_CLASS_REQUEST_SUCCESS,
  payload: data,
});

export const getRequestClassError = (error: string): RequestClassAction => ({
  type: RequestClassActionTypes.GET_REQUEST_CLASS_REQUEST_ERROR,
  payload: error,
});

export const requestClass = (data: any): RequestClassAction => ({
  type: RequestClassActionTypes.REQUEST_CLASS_REQUEST,
  payload: data,
});

export const requestClassSuccess = (data: any): RequestClassAction => ({
  type: RequestClassActionTypes.REQUEST_CLASS_REQUEST_SUCCESS,
  payload: data,
});

export const requestClassError = (error: string): RequestClassAction => ({
  type: RequestClassActionTypes.REQUEST_CLASS_REQUEST_ERROR,
  payload: error,
});

export const updateRequestClass = (data: any): RequestClassAction => ({
  type: RequestClassActionTypes.UPDATE_REQUEST_CLASS_REQUEST,
  payload: data,
});

export const updateRequestClassSuccess = (data: any): RequestClassAction => ({
  type: RequestClassActionTypes.UPDATE_REQUEST_CLASS_REQUEST_SUCCESS,
  payload: data,
});

export const updateRequestClassError = (error: string): RequestClassAction => ({
  type: RequestClassActionTypes.UPDATE_REQUEST_CLASS_REQUEST_ERROR,
  payload: error,
});
