import {
  RequestClassAction,
  RequestClassActionTypes,
} from "../actions/requestClassActionTypes";

interface RequestClassState {
  data: string[];
  loading: boolean;
  error: string | null;
  updated: string[];
  updating: boolean;
  updateError: string | null;
}

const initialState: RequestClassState = {
  data: [],
  loading: false,
  error: null,
  updated: [],
  updating: false,
  updateError: null,
};

const requestClassReducer = (
  state = initialState,
  action: RequestClassAction
): RequestClassState => {
  switch (action.type) {
    case RequestClassActionTypes.GET_REQUEST_CLASS_REQUEST:
      return { ...state, loading: true };
    case RequestClassActionTypes.GET_REQUEST_CLASS_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case RequestClassActionTypes.GET_REQUEST_CLASS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case RequestClassActionTypes.REQUEST_CLASS_REQUEST:
      return { ...state, loading: true, data: action.payload };
    case RequestClassActionTypes.REQUEST_CLASS_REQUEST_SUCCESS:
      return { ...state, loading: false, error: null };
    case RequestClassActionTypes.REQUEST_CLASS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case RequestClassActionTypes.UPDATE_REQUEST_CLASS_REQUEST:
      return { ...state, updating: true, updated: action.payload };
    case RequestClassActionTypes.UPDATE_REQUEST_CLASS_REQUEST_SUCCESS:
      return { ...state, updating: false, updated: action.payload };
    case RequestClassActionTypes.UPDATE_REQUEST_CLASS_REQUEST_ERROR:
      return { ...state, updating: false, updateError: action.payload };
    default:
      return state;
  }
};

export default requestClassReducer;
