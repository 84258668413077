export enum AIAgentsActionTypes {
  ReadingComprehension_REQUEST = 'aiAgents/ReadingComprehension_REQUEST',
  ReadingComprehension_REQUEST_SUCCESS = 'aiAgents/ReadingComprehension_REQUEST_SUCCESS',
  ReadingComprehension_REQUEST_ERROR = 'aiAgents/ReadingComprehension_REQUEST_ERROR',
  GradeReadingComprehension_REQUEST = 'aiAgents/GradeReadingComprehension_REQUEST',
  GradeReadingComprehension_REQUEST_SUCCESS = 'aiAgents/GradeReadingComprehension_REQUEST_SUCCESS',
  GradeReadingComprehension_REQUEST_ERROR = 'aiAgents/GradeReadingComprehension_REQUEST_ERROR',
  MatchTheFollowing_REQUEST = 'aiAgents/MatchTheFollowing_REQUEST',
  MatchTheFollowing_REQUEST_SUCCESS = 'aiAgents/MatchTheFollowing_REQUEST_SUCCESS',
  MatchTheFollowing_REQUEST_ERROR = 'aiAgents/MatchTheFollowing_REQUEST_ERROR',
  GradeMatchTheFollowing_REQUEST = 'aiAgents/GradeMatchTheFollowing_REQUEST',
  GradeMatchTheFollowing_REQUEST_SUCCESS = 'aiAgents/GradeMatchTheFollowing_REQUEST_SUCCESS',
  GradeMatchTheFollowing_REQUEST_ERROR = 'aiAgents/GradeMatchTheFollowing_REQUEST_ERROR',
  Chess_REQUEST = 'aiAgents/Chess_REQUEST',
  Chess_REQUEST_SUCCESS = 'aiAgents/Chess_REQUEST_SUCCESS',
  Chess_REQUEST_ERROR = 'aiAgents/Chess_REQUEST_ERROR',
  GradeChess_REQUEST = 'aiAgents/GradeChess_REQUEST',
  GradeChess_REQUEST_SUCCESS = 'aiAgents/GradeChess_REQUEST_SUCCESS',
  GradeChess_REQUEST_ERROR = 'aiAgents/GradeChess_REQUEST_ERROR',
  Spelling_REQUEST = 'aiAgents/Spelling_REQUEST',
  Spelling_REQUEST_SUCCESS = 'aiAgents/Spelling_REQUEST_SUCCESS',
  Spelling_REQUEST_ERROR = 'aiAgents/Spelling_REQUEST_ERROR',
  GradeSpelling_REQUEST = 'aiAgents/GradeSpelling_REQUEST',
  GradeSpelling_REQUEST_SUCCESS = 'aiAgents/GradeSpelling_REQUEST_SUCCESS',
  GradeSpelling_REQUEST_ERROR = 'aiAgents/GradeSpelling_REQUEST_ERROR',
  Phonics_REQUEST = 'aiAgents/Phonics_REQUEST',
  Phonics_REQUEST_SUCCESS = 'aiAgents/Phonics_REQUEST_SUCCESS',
  Phonics_REQUEST_ERROR = 'aiAgents/Phonics_REQUEST_ERROR',
  GradePhonics_REQUEST = 'aiAgents/GradePhonics_REQUEST',
  GradePhonics_REQUEST_SUCCESS = 'aiAgents/GradePhonics_REQUEST_SUCCESS',
  GradePhonics_REQUEST_ERROR = 'aiAgents/GradePhonics_REQUEST_ERROR',
  MusicTest_REQUEST = 'aiAgents/MusicTest_REQUEST',
  MusicTest_REQUEST_SUCCESS = 'aiAgents/MusicTest_REQUEST_SUCCESS',
  MusicTest_REQUEST_ERROR = 'aiAgents/MusicTest_REQUEST_ERROR',
  GradeMusicTest_REQUEST = 'aiAgents/GradeMusicTest_REQUEST',
  GradeMusicTest_REQUEST_SUCCESS = 'aiAgents/GradeMusicTest_REQUEST_SUCCESS',
  GradeMusicTest_REQUEST_ERROR = 'aiAgents/GradeMusicTest_REQUEST_ERROR',
  SATTutor_REQUEST = 'aiAgents/SATTutor_REQUEST',
  SATTutor_REQUEST_SUCCESS = 'aiAgents/SATTutor_REQUEST_SUCCESS',
  SATTutor_REQUEST_ERROR = 'aiAgents/SATTutor_REQUEST_ERROR',
  GradeSATTutor_REQUEST = 'aiAgents/GradeSATTutor_REQUEST',

  GradeSATTutor_REQUEST_SUCCESS = 'aiAgents/GradeSATTutor_REQUEST_SUCCESS',
  GradeSATTutor_REQUEST_ERROR = 'aiAgents/GradeSATTutor_REQUEST_ERROR'
}

export interface AIAgentsAction {
  type: AIAgentsActionTypes;
  payload?: any;
}
