import { CareersActionTypes, CareersAction } from '../actions/careersActionTypes';

interface CareersState {
  data: any;
  loading: boolean;
  error: string | null;
  updating: boolean;
  updated_data: string[];
  deleting: boolean;
  filtered_data: any[];
  deleted_data: string[];
}

const initialState: CareersState = {
  data: [],
  loading: false,
  error: null,
  updating: false,
  updated_data: [],
  deleting: false,
  deleted_data: [],
  filtered_data: []
};

const careersReducer = (state = initialState, action: CareersAction): CareersState => {
  switch (action.type) {
    case CareersActionTypes.CAREERS_REQUEST:
      return { ...state, loading: true };
    case CareersActionTypes.CAREERS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case CareersActionTypes.CAREERS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CareersActionTypes.CAREERS_UPDATE_REQUEST:
      return { ...state, updating: true };
    case CareersActionTypes.CAREERS_UPDATE_REQUEST_SUCCESS:
      return { ...state, updated_data: action.payload, updating: false, error: null };
    case CareersActionTypes.CAREERS_UPDATE_REQUEST_ERROR:
      return { ...state, updating: false, error: action.payload };

    case CareersActionTypes.CAREERS_DELETE_REQUEST:
      return { ...state, deleting: true };
    case CareersActionTypes.CAREERS_DELETE_REQUEST_SUCCESS:
      return { ...state, deleted_data: action.payload, deleting: false, error: null };
    case CareersActionTypes.CAREERS_DELETE_REQUEST_ERROR:
      return { ...state, deleting: false, error: action.payload };

    case CareersActionTypes.CAREERS_FILTER_REQUEST: {
      const { 
        employmentType, 
        senioritylevel, 
        salaryRange, 
        currentJobs 
      }: any = action.payload;

      // If no filters are selected, return all jobs
      if (!currentJobs?.message) {
        return {
          ...state,
          filtered_data: [],
          loading: false,
          error: null,
        };
      }

      let filteredJobs = currentJobs.message;

      // Apply employment type filter
      if (employmentType && !employmentType.includes('all')) {
        filteredJobs = filteredJobs.filter((job) => 
          employmentType.includes(job.employment)
        );
      }

      // Apply seniority level filter
      if (senioritylevel && !senioritylevel.includes('all')) {
        filteredJobs = filteredJobs.filter((job) => 
          senioritylevel.includes(job.seniority)
        );
      }

      // Apply salary range filter
      if (salaryRange && !salaryRange.includes('all')) {
        filteredJobs = filteredJobs.filter((job) => 
          salaryRange.includes(job.salaryRange)
        );
      }

      return {
        ...state,
        filtered_data: filteredJobs,
        loading: false,
        error: null,
      };
    }

    default:
      return state;
  }
};

export default careersReducer;
