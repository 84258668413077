// features/createClass/actions/createClassActions.ts
import { getGradingAssistantsActionTypes, getGradingAssistantsAction } from './getGradingAssistantsActionTypes';

export const getGradingAssistantsRequest = (data: any): getGradingAssistantsAction => ({
  type: getGradingAssistantsActionTypes.GET_GRADING_ASSISTANTS,
  payload: data
});

export const getGradingAssistantsRequestSuccess = (data: any): getGradingAssistantsAction => ({
  type: getGradingAssistantsActionTypes.GET_GRADING_ASSISTANTS_SUCCESS,
  payload: data
});

export const getGradingAssistantsRequestError = (error: string): getGradingAssistantsAction => ({
  type: getGradingAssistantsActionTypes.GET_GRADING_ASSISTANTS_ERROR,
  payload: error
});
