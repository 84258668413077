import { takeLatest, put, call } from "redux-saga/effects";
import { apiService } from "../../../services/api";
import {
  getRequestClassSuccess,
  getRequestClassError,
  requestClassError,
  requestClassSuccess,
  updateRequestClassSuccess,
  updateRequestClassError,
} from "../actions/requestClassActions";
import { RequestClassActionTypes } from "../actions/requestClassActionTypes";

function* getRequestClassSaga(req) {
  try {
    const response = yield call(apiService.requestClass, req.payload);
    yield put(getRequestClassSuccess(response));
  } catch (error: any) {
    yield put(getRequestClassError(error.message));
  }
}

function* requestClassSaga(req) {
  try {
    const response = yield call(apiService.requestClass, req.payload);
    yield put(requestClassSuccess(response));
  } catch (error: any) {
    yield put(requestClassError(error.message));
  }
}

function* updateRequestClassSaga(req) {
  try {
    const response = yield call(apiService.requestClass, req.payload);
    yield put(updateRequestClassSuccess(response));
  } catch (error: any) {
    yield put(updateRequestClassError(error.message));
  }
}

export function* watchRequestClass() {
  yield takeLatest(
    RequestClassActionTypes.GET_REQUEST_CLASS_REQUEST,
    getRequestClassSaga
  );
  yield takeLatest(
    RequestClassActionTypes.REQUEST_CLASS_REQUEST,
    requestClassSaga
  );
  yield takeLatest(
    RequestClassActionTypes.UPDATE_REQUEST_CLASS_REQUEST,
    updateRequestClassSaga
  );
}
