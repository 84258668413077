import { takeLatest, put, call } from 'redux-saga/effects';
import { getGradingAssistantsActionTypes } from '../actions/getGradingAssistantsActionTypes';
import {
  getGradingAssistantsRequestSuccess,
  getGradingAssistantsRequestError
} from '../actions/getGradingAssistantsActions';
import { apiService } from '../../../services/api';

function* getGradingAssistantsRequestSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getGradingAssistants, req.payload);

    // Dispatch the success action with the API response
    yield put(getGradingAssistantsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getGradingAssistantsRequestError(error.message));
  }
}

export function* watchgetGradingAssistantsRequest() {
  yield takeLatest(getGradingAssistantsActionTypes.GET_GRADING_ASSISTANTS, getGradingAssistantsRequestSaga);
}
