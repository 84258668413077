import { takeLatest, put, call } from 'redux-saga/effects';
import { Gen3RewardsActionTypes } from '../actions/gen3RewardsActionTypes';
import {
  gen3RewardsRequestSuccess,
  gen3RewardsRequestError,
  gen3RewardsClaimRequestSuccess,
  gen3RewardsClaimRequestError,
  gen3UpdateRewardsSuccess,
  gen3UpdateRewardsError
} from '../actions/gen3RewardsActions';
import { apiService } from '../../../services/api';

function* gen3RewardsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.gen3Rewards, req.payload);

    // Dispatch the success action with the API response
    yield put(gen3RewardsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(gen3RewardsRequestError(error.message));
  }
}

function* gen3RewardsClaimRequestSaga(req) {
  try {
    const response = yield call(apiService.gen3Rewards, req.payload);
    yield put(gen3RewardsClaimRequestSuccess(response));
  } catch (error: any) {
    yield put(gen3RewardsClaimRequestError(error.message));
  }
}

function* gen3UpdateRewardsSaga(req) {
  try {
    const response = yield call(apiService.gen3Rewards, req.payload);
    yield put(gen3UpdateRewardsSuccess(response));
  } catch (error: any) {
    yield put(gen3UpdateRewardsError(error.message));
  }
}
export function* watchGen3RewardsRequest() {
  yield takeLatest(Gen3RewardsActionTypes.GEN3_REWARDS_REQUEST, gen3RewardsRequestSaga);
  yield takeLatest(Gen3RewardsActionTypes.GEN3_REWARDS_CLAIM_REQUEST, gen3RewardsClaimRequestSaga);
  yield takeLatest(Gen3RewardsActionTypes.GEN3_UPDATE_REWARDS, gen3UpdateRewardsSaga);
}
