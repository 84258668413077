import { GetResourcesActionTypes, GetResourcesAction } from '../actions/getResourcesActionTypes';

interface GetResourcesState {
  data: any;
  dataById: string[];
  managing: boolean;
  manage_error: string | null;
  resourcesOnCart: any;
  updatedCart: any;
}

const initialState: GetResourcesState = {
  data: [],
  dataById: [],
  managing: false,
  manage_error: null,
  resourcesOnCart: null,
  updatedCart: null
};
function calculateAverageRating(ratings) {
  const scores: any = Object.values(ratings);

  const totalScore: any = scores.reduce((acc, score) => acc + score, 0);
  const count = scores.length;
  const averageRating = count > 0 ? totalScore / count : 0;
  const cappedAverage = Math.min(averageRating, 5).toFixed(1);
  return cappedAverage;
}

const getResourcesReducer = (state = initialState, action: GetResourcesAction): GetResourcesState => {
  switch (action.type) {
    case GetResourcesActionTypes.GET_RESOURCES:
      return { ...state, data: action.payload, managing: true };
    case GetResourcesActionTypes.GET_RESOURCES_SUCCESS:
      localStorage.setItem('resources', JSON.stringify(action.payload));
      return { ...state, data: action.payload, managing: false, manage_error: null };
    case GetResourcesActionTypes.GET_RESOURCES_ERROR:
      return { ...state, managing: false, manage_error: action.payload };
    case GetResourcesActionTypes.GET_RESOURCES_BY_ID:
      return { ...state, managing: true };
    case GetResourcesActionTypes.GET_RESOURCES_BY_ID_SUCCESS:
      return { ...state, dataById: action.payload, managing: false, manage_error: null };
    case GetResourcesActionTypes.GET_RESOURCES_BY_ID_ERROR:
      return { ...state, managing: false, manage_error: action.payload };
    case GetResourcesActionTypes.REMOVE_RESOURCES_ON_CART:
      const { resource_id } = action.payload;
      const updated_cart = JSON.parse(localStorage.getItem('cart')).filter(
        (resource) => resource.resource_id !== resource_id
      );
      localStorage.setItem('cart', JSON.stringify(updated_cart));
      return { ...state, updatedCart: updated_cart };
    case GetResourcesActionTypes.GET_RESOURCES_BY_Filters: {
      const { categories: filterCategories, duration: filterDuration, rating: filterRating } = action.payload;
      const resourcesData = JSON.parse(localStorage.getItem('resources'));
      if (!resourcesData) {
        return state;
      }

      const resources = resourcesData.message;
      let low = Math.min(filterDuration[0], filterDuration[1]);
      let high = Math.max(filterDuration[0], filterDuration[1]);

      const filteredMessageByFilters = resources.filter((resource) => {
        console.log('Resource categories:', resource.categories);
        console.log('Filter categories:', filterCategories);

        const categoriesMatch = resource.categories.some((category) =>
          filterCategories.includes(category.charAt(0).toUpperCase() + category.slice(1))
        );

        console.log('Categories match:', categoriesMatch);

        const durationMatch = resource.rent_duration >= low && resource.rent_duration <= high;

        const ratingMatch = Number(calculateAverageRating(resource?.ratings ?? {})) === Number(filterRating);
        console.log('Rating match:', ratingMatch);
        console.log('Duration match:', durationMatch);
        return categoriesMatch || durationMatch || ratingMatch;
      });

      return {
        ...state,
        data: {
          ...state.data,
          message: filteredMessageByFilters.length > 0 ? filteredMessageByFilters : []
        }
      };
    }
    case GetResourcesActionTypes.RESET_RESOURCES_ON_CART:
      localStorage.removeItem('cart');
      return { ...state, resourcesOnCart: [] };
    case GetResourcesActionTypes.STORE_RESOURCES_ON_CART:
      let resourcesOnCart: any = localStorage.getItem('cart');

      if (resourcesOnCart) {
        resourcesOnCart = JSON.parse(resourcesOnCart);
      }
      if (!resourcesOnCart) {
        resourcesOnCart = [];
      }
      let findResource = resourcesOnCart?.find((resource: any) => resource.resource_id === action.payload.resource_id);
      if (!findResource) {
        console.log('not found');
        resourcesOnCart.push(action.payload);
      }
      localStorage.setItem('cart', JSON.stringify(resourcesOnCart));
      return { ...state, resourcesOnCart: resourcesOnCart };
    case GetResourcesActionTypes.FILTER_RESOURCES_ON_CART:
      const resourceId = action.payload;
      const AllresourcesOnCart: any = JSON.parse(localStorage.getItem('cart'));
      const updatedCart = AllresourcesOnCart.filter((resource: any) => resource.resource_id !== resourceId);
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      return { ...state, resourcesOnCart: updatedCart };
    default:
      return state;
  }
};

export default getResourcesReducer;
