export enum FreePostingsActionTypes {
  ADD_FREE_POSTING_REQUEST = 'freePostings/ADD_FREE_POSTING_REQUEST',
  ADD_FREE_POSTING_REQUEST_SUCCESS = 'freePostings/ADD_FREE_POSTING_REQUEST_SUCCESS',
  ADD_FREE_POSTING_REQUEST_ERROR = 'freePostings/ADD_FREE_POSTING_REQUEST_ERROR',
  GET_FREE_POSTINGS_REQUEST = 'freePostings/GET_FREE_POSTINGS_REQUEST',
  GET_FREE_POSTINGS_REQUEST_SUCCESS = 'freePostings/GET_FREE_POSTINGS_REQUEST_SUCCESS',
  GET_FREE_POSTINGS_REQUEST_ERROR = 'freePostings/GET_FREE_POSTINGS_REQUEST_ERROR',
  UPDATE_FREE_POSTING_REQUEST = 'freePostings/UPDATE_FREE_POSTING_REQUEST',
  UPDATE_FREE_POSTING_REQUEST_SUCCESS = 'freePostings/UPDATE_FREE_POSTING_REQUEST_SUCCESS',
  UPDATE_FREE_POSTING_REQUEST_ERROR = 'freePostings/UPDATE_FREE_POSTING_REQUEST_ERROR',
}

export interface FreePostingsAction {
  type: FreePostingsActionTypes;
  payload?: any;
}
