import {
  FreePostingsAction,
  FreePostingsActionTypes
} from './freePostingsActionTypes';

export const addFreePostingRequest = (data: any): FreePostingsAction => ({
  type: FreePostingsActionTypes.ADD_FREE_POSTING_REQUEST,
  payload: data
});

export const addFreePostingRequestSuccess = (data: any): FreePostingsAction => ({
  type: FreePostingsActionTypes.ADD_FREE_POSTING_REQUEST_SUCCESS,
  payload: data
});

export const addFreePostingRequestError = (error: string): FreePostingsAction => ({
  type: FreePostingsActionTypes.ADD_FREE_POSTING_REQUEST_ERROR,
  payload: error
});

export const getFreePostings = (data: any): FreePostingsAction => ({
  type: FreePostingsActionTypes.GET_FREE_POSTINGS_REQUEST,
  payload: data
});

export const getFreePostingsSuccess = (data: any): FreePostingsAction => ({
  type: FreePostingsActionTypes.GET_FREE_POSTINGS_REQUEST_SUCCESS,
  payload: data
});

export const getFreePostingsError = (error: string): FreePostingsAction => ({
  type: FreePostingsActionTypes.GET_FREE_POSTINGS_REQUEST_ERROR,
  payload: error
});

export const updateFreePostingRequest = (data: any): FreePostingsAction => ({
  type: FreePostingsActionTypes.UPDATE_FREE_POSTING_REQUEST,
  payload: data
});

export const updateFreePostingRequestSuccess = (data: any): FreePostingsAction => ({
  type: FreePostingsActionTypes.UPDATE_FREE_POSTING_REQUEST_SUCCESS,
  payload: data
});


export const updateFreePostingRequestError = (error: string): FreePostingsAction => ({
  type: FreePostingsActionTypes.UPDATE_FREE_POSTING_REQUEST_ERROR,
  payload: error
});
