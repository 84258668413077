export enum manageGradingAssistantsActionTypes {
  MANAGE_GRADING_ASSISTANTS = 'manageGradingAssistants/MANAGE_GRADING_ASSISTANTS',
  MANAGE_GRADING_ASSISTANTS_SUCCESS = 'manageGradingAssistants/MANAGE_GRADING_ASSISTANTS_SUCCESS',
  MANAGE_GRADING_ASSISTANTS_ERROR = 'manageGradingAssistants/MANAGE_GRADING_ASSISTANTS_ERROR'
}

export interface manageGradingAssistantsAction {
  type: manageGradingAssistantsActionTypes;
  payload?: any;
}
